.main-section {
  width: 100vw;
  min-height: 100vh;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  width: 70%;
  height: 50px;
  display: block;
  margin: 20px auto 0;
  padding: 0 20px;
  border-radius: 8px;
  border-width: 1px;
}

.btn {
  width: 200px;
  margin-top: 30px;
}
.btn-danger,
.prev-meeting,
.current-meeting {
  display: none;
}
.prev-meeting {
  margin-top: 40px;
  padding-top: 40px;
  width: 100%;
  min-width: 60vw;
  border-top: 1px solid #000;
}

.current-meeting {
  margin-top: 40px; 
}
.transcript{
  width: 80vw;
  height: 300px;
  overflow-y: scroll;
  border: 1px solid;
  padding: 20px;
}
h2 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: anywhere;
}
